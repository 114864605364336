const trimArrayObjectsProps = (storesJSON) => {
    const trimmedArray = storesJSON.map(obj =>  
        Object.keys(obj).reduce((acc, key) => {
          acc[key] = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
          return acc;
        }, {})
    );

    return trimmedArray
}


module.exports = {
    trimArrayObjectsProps
}