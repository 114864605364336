<template>
   <footer class="py-5">
    <div class="container">
        <div class="row">
          <div class="col-12 d-md-flex d-block justify-content-center">
            <img  class="img-fluid mx-md-0 mx-auto d-block" style="max-width: 320px;" src="img/marcas-white.svg" alt="Americanino | Chevignon | Esprit">
          </div>
          <div class="col-12 mt-3">
            <p class="text-uppercase text-white text-center mb-0">Todos los derechos reservados Americanino | CHEVIGNON | Esprit © 2025</p>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>

export default {
  name: "Footer",
};
</script>
