<template>
  <div class="home">
    <div class="bg-sales d-flex justify-content-lg-center align-items-lg-end align-items-center">
      <div class="container">
        <div class="d-flex align-items-center justify-content-end pt-3">
          <div>
            <button type="button" class="btn btn-danger fw-bold"><router-link class="text-decoration-none text-reset" to="/registro">Registrarse</router-link></button>
          </div>
        </div>
        <div class="row py-5">
          <div class="col-md-6 col-12 mx-auto align-self-center">
            <img class="img-fluid mx-auto d-block logo fade-image" src="img/Logo-Sales.png" alt="sale">
            <img class="img-fluid mx-auto d-block imgDiscount my-4" src="img/logo-descuento.svg" alt="sale">
            <h6 class="text-center text-white fw-bold">DEL 3 DE ENERO AL 2 DE FEBRERO DE 2025</h6>
            <p class="text-center text-white">*Aplica en referencias seleccionadas</p>
          </div>
          <div class="col-md-8 col-12 mx-auto p-3 align-self-end">
            <img  class="img-fluid mx-auto d-block" src="img/marcas-white.svg" alt="AMERICANINO|CHEVIGNON|ESPRIT">
          </div>
        </div> 
      </div>
    </div>
    <div class="distribuidores py-5" id="distribuidores">
      <div class="container">
        <div class="row mx-0">
          <div class="col-12">
            <h3 class="text-center text-uppercase text-acmno mb-5">Distribuidores autorizados</h3>
            <div class="table-responsive">
              <table id="dataTable" class="table table-striped" style="width:100%">
                <thead>
                    <tr>
                        <th>Ciudad</th>
                        <th>Zona</th>
                        <th>Tienda</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(store, index) in stores" :key="index">
                        <td>{{store.ciudad}}</td>
                        <td>{{store.zona}}</td>
                        <td>{{store.nombre_cliente}}</td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>

import Stores from '../stores.json'
import Footer from '@/components/Footer.vue'
import { trimArrayObjectsProps } from '@/helpers/funciones'

export default {
  name: 'HomeView',
  data() {
    return {
      stores: []
    }
  },
  created(){
    // this.stores = trimArrayObjectsProps(Stores).sort((a, b) => a.ciudad.localeCompare(b.ciudad));
    this.stores = trimArrayObjectsProps(Stores);
  },
  components: {
    Footer
  }

}
</script>
