
import { preciosArray } from "@/precios";

export const filtrarCiudades = (array) => {
  
    let cities = array.map(element => element.ciudad).sort()
    const citiesJson = [];
    cities.forEach((item)=>{
    	//pushes only unique element
        if(!citiesJson.includes(item)){
    		citiesJson.push(item);
    	}
    })

    return citiesJson;
}


export const filtrarTiendasPorCiudad = (array, ciudad) => {
  let tiendas = array.filter(elemento  => {
     return elemento.ciudad === ciudad;
  }).map(elemento => elemento.nombre_cliente);

  return tiendas;
}

export const obtenerZona = (ciudad, tienda, array) => {
  let zonaObtenida = array.filter(elemento => {
    return elemento.nombre_cliente === tienda && elemento.ciudad === ciudad
  })

  return zonaObtenida[0].zona;
}

export const obtenerDatosAdicionales = (ciudad, tienda, array) => {
  let datosAdicionales = array.filter(elemento => {
    return elemento.nombre_cliente === tienda && elemento.ciudad === ciudad
  })

  return datosAdicionales[0];
}

export const obtenerPreciosPorMarcaYReferencia = async (marca, referencia) => {

  const precioFiltrado =  preciosArray.filter(precioObj => precioObj['marca'] === marca.toUpperCase() && precioObj['ref'] === referencia);
  let precioLista;
  let precioPublico;
  if(precioFiltrado.length > 0) {
    precioLista = precioFiltrado[0]['precio_lista'];
    precioPublico = precioFiltrado[0]['precio_publico'];
  } else {
    precioLista = 'Precio no encontrado';
    precioPublico = 'Precio no encontrado';
  }

  return { precioLista, precioPublico };
}