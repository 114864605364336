
export const validaReferecias = (referenciasObj) => {

    const caracteresPermitidos = ['A', 'B', 'C', 'D', 'E', 'F', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    let refValida = true;

    referenciasObj.forEach(referencia => {

        if(referencia.ref.length !== 7) {
            refValida = false
        }else{

            const primerFragmento = referencia.ref.substring(0, 3);
            if(!validaNumerico(primerFragmento)) refValida = false

            const segundoFragmento = referencia.ref[3].toUpperCase();
            // if(!isNaN(segundoFragmento)) return false;
            if(!caracteresPermitidos.includes(segundoFragmento)) refValida = false
    
            const tercerFragmento = referencia.ref.substring(4);
            if(!validaNumerico(tercerFragmento)) refValida = false
        }
    });

    return refValida;
} 


export const validaNumerico = (fragmento) => {
    let aux = true;
    for(let i = 0; i < fragmento.length; i ++) {
        const caracterInt = parseInt(fragmento[i]);
        if(isNaN(caracterInt)) {
            aux = false;
        }
    }  
    return aux
}


export const validaString = (fragmento) => {
    let specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let numbers = /[0-9]/;
    let resSpecialChars = specialChars.test(fragmento);
    let resNumbers = numbers.test(fragmento);;
    if(resSpecialChars || resNumbers) {
        return false
    }
    return true;
}

export const validaExtensionArchivo = (archivo) => {
    const extensionesPermitidas = ['png','PNG','jpg','JPG','jpeg','JPEG','bmp','BMP','pdf','PDF'];
    const nombreArchivo = archivo.name;
    const nombreArchivoArray = nombreArchivo.split('.');
    const extensionArchivo = nombreArchivoArray[nombreArchivoArray.length - 1];
    if(!extensionesPermitidas.includes(extensionArchivo)){
        return false;
    }

    return true;
}